<template>
  <div class="column-search">
    <div class="field">
      <div class="control control-input has-icons-right has-margin-right">
        <input
          type="search"
          v-model="mSearchTerm"
          v-on:keyup.13="startSearch"
          class="input"
          :placeholder="placeHolderText"
        />
        <span
          @click="clearSearch"
          v-if="mSearchTerm.length > 0"
          class="icon is-right"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </span>
      </div>

      <button
        @click="startSearch"
        class="button is-primary"
        :class="{ 'is-loading': isSearching }"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'search']" />
        </span>
        <span>{{$t('Components.SearchBar.Icon_Search')}}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSearching: {
      default: false,
      type: Boolean
    },

    placeHolderText: {
      default: "",
      type: String
    },

    searchTerm: {
      default: "",
      type: String
    }
  },

  data() {
    return {
      mSearchTerm: this.searchTerm
    };
  },

  methods: {
    startSearch() {
      this.$emit("startSearch", this.mSearchTerm);
    },

    clearSearch() {
      this.mSearchTerm = "";
      this.startSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
.column-search {
  .field {
    display: flex;
    flex-direction: row;
    .control-input {
      flex: 1;
    }
  }
}
</style>
